import {
  Component, ContentChild, Input, TemplateRef,
} from '@angular/core';
import BasicRxComponent from '@components/BasicRxComponent';

@Component({
  selector: 'app-section-bottom-tab',
  template: '',
})
export class SectionBottomTabComponent
  extends BasicRxComponent
{
  @Input() desktopTitleWidth: string = '1fr';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ContentChild('title', { static: true }) title!: TemplateRef<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ContentChild('content', { static: true }) content!: TemplateRef<any>;
}
