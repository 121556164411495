/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChapterDisplayMode,
  ChapterPriority,
  ChapterType,
  ChapterTypeInfo,
  IChapter
} from '@models/chapter';
import { isValidObjectId } from './ObjectId';
import { IColumn } from '@models/course';

export const sortDeleted = (chapter: IChapter[], predicate?) => {
  return !chapter
    ? null
    : chapter.reduce((list, entry) => {
        let clone = null;
        if (predicate(entry)) {
          clone = { ...entry };
        } else if (entry.subchapters !== null) {
          const subchapters = sortDeleted(entry.subchapters, predicate);
          if (subchapters.length > 0) {
            clone = { ...entry, subchapters };
          }
        }
        clone && list.push(clone);
        return list;
      }, []);
};

export const allChapterTypes = [
  ChapterType.summaryChapter,
  ChapterType.exam,
  ChapterType.examAssignment,
  ChapterType.examByTopicChapter,
  ChapterType.additionalTasks
];

export const allChapterDisplayModes = [
  ChapterDisplayMode.hierachical,
  ChapterDisplayMode.includeChildren,
  ChapterDisplayMode.includedInParent
];

export const allChapterPriorities = [
  ChapterPriority.normal,
  ChapterPriority.highlighted
];

interface TreeChapter {
  chapter: IChapter;
  pathArray: string[];
}

export function orderStringToFloat(str: string): number {
  const splitString = str.split(',');
  if (splitString.length == 0) {
    return 0;
  }
  let floatString = splitString[0];
  if (splitString.length == 1) {
    return parseFloat(floatString); //{x}
  }
  floatString =
    floatString +
    '.' +
    splitString
      .slice(1, splitString.length)
      .map((s) => s.split('.').join(''))
      .join('');
  return parseFloat(floatString);
}

export function chapterSortFunction(chapter1, chapter2) {
  const a = chapter1.orderString;
  const b = chapter2.orderString;
  const aElements = a.split(',').map((i) => {
    return parseFloat(i);
  });
  const bElements = b.split(',').map((i) => {
    return parseFloat(i);
  });
  const count = Math.max(aElements.length, bElements.length);
  let bBiggerThanA = false;
  //set result to 1 if b bigger a
  for (let i = 0; i < count; i++) {
    if (i < aElements.length && i < bElements.length) {
      if (aElements[i] == bElements[i]) {
        //continue
      } else {
        bBiggerThanA = bElements[i] > aElements[i];
        break;
      }
    } else {
      bBiggerThanA = bElements.length > aElements.length;
    }
  }
  return bBiggerThanA ? -1 : 1;
}

export function chapter_tree_from_paths2(
  chapters: IChapter[],
  root: string,
  toObjects = false
): IChapter[] {
  //level at which root is in path
  let rootLevel: number = null;
  const treeChapters = chapters
    .map((c) => (toObjects ? (c as any).toObject() : c))
    .map((chapter): TreeChapter => {
      const path = chapter.path.split(',');
      if (null === rootLevel) {
        const index = path.indexOf(root);
        if (index < -1) {
          return null;
        }
        rootLevel = index + 1;
      }
      path.splice(0, rootLevel);
      chapter.subchapters = [];

      if (toObjects) {
        chapter.subchapters = [];
      } else {
        (chapter as any).set('subchapters', [], { strict: 'throw' });
      }
      return {
        chapter: chapter,
        pathArray: path
      };
    })
    .filter(Boolean);

  const subCache: { [id: string]: IChapter[] } = {};
  const titleSubCache: { [id: string]: string[] } = {};
  const hasSubs: IChapter[] = [];

  for (const tc of treeChapters) {
    if (tc.pathArray.length > 0) {
      const treeParent = treeChapters
        .filter(Boolean)
        .find(
          (chapter) =>
            chapter.chapter._id.toString() ==
            tc.pathArray[tc.pathArray.length - 1]
        );
      if (!treeParent) {
        continue;
      }
      const parent = treeParent.chapter;

      subCache[parent._id.toString()] = subCache[parent._id.toString()] || [];
      subCache[parent._id.toString()].push(tc.chapter);

      titleSubCache[parent.title.toString()] =
        titleSubCache[parent.title.toString()] || [];
      titleSubCache[parent.title.toString()].push(tc.chapter.title);

      if (
        hasSubs.findIndex(
          (val) => val._id.toString() == parent._id.toString()
        ) == -1
      ) {
        hasSubs.push(parent);
      }
    }
  }

  for (const hasSub of hasSubs) {
    const sortedSubs =
      subCache[hasSub._id.toString()].sort(chapterSortFunction);
    if (toObjects) {
      hasSub.subchapters = sortedSubs;
    } else {
      (hasSub as any).set('subchapters', sortedSubs, { strict: 'throw' });
    }
  }

  const result = treeChapters
    .filter((tc) => tc.pathArray.length == 0)
    .map((tc) => tc.chapter)
    .sort(chapterSortFunction);

  applyHierarchical(result, (parent: IChapter, child: IChapter) => {
    const hs = hasSubs.find((h) => child._id.toString() == h._id.toString());
    if (hs) {
      child.subchapters = hs.subchapters;
    }
  });
  return result;
}

export function chapter_tree_from_paths(
  chapters: IChapter[] | TreeChapter[],
  root: string
): IChapter[] {
  return _chapter_tree_from_paths(chapters, root).map((c) => c.chapter);
}

export function displayModeForChildrenAfterModification(
  parentPrevious: ChapterDisplayMode,
  parentNew: ChapterDisplayMode
) {
  if (parentPrevious == parentNew) {
    return null;
  } else if (parentPrevious != ChapterDisplayMode.hierachical) {
    return ChapterDisplayMode.hierachical;
  } else {
    return ChapterDisplayMode.includedInParent;
  }
}

export function _chapter_tree_from_paths(
  chapters: IChapter[] | TreeChapter[],
  root: string,
  arrayMapped = false
): TreeChapter[] {
  const arrayMap: TreeChapter[] = arrayMapped
    ? (chapters as TreeChapter[]).map((treechapter) => {
        treechapter.pathArray.splice(0, 1);
        return treechapter;
      })
    : (chapters as IChapter[])
        .map((chapter) => {
          const path = chapter.path.split(',');
          if (!path || path.length == 0 || path[0] != root) {
            return null;
          }
          path.splice(0, 1);
          return {
            chapter: chapter,
            pathArray: path
          };
        })
        .filter(Boolean);
  if (arrayMapped) {
    return chapters as TreeChapter[];
  }
  let roots = (arrayMap as TreeChapter[]).filter(
    (c) => c.pathArray.length == 0
  );
  roots = roots.map((rootTreeChapter) => {
    const rootChapter = rootTreeChapter.chapter;
    const rootKey = rootChapter.url_slug;

    const filtered_sub = arrayMap.filter((chapter) => {
      return chapter.pathArray.length > 0 && rootKey == chapter.pathArray[0];
    });
    const subchapters = _chapter_tree_from_paths(filtered_sub, rootKey, true);

    (rootChapter as any).set(
      'subchapters',
      subchapters.map((c) => c.chapter),
      { strict: 'throw' }
    );
    return {
      chapter: rootChapter,
      pathArray: rootTreeChapter.pathArray
    };
  });
  return roots;
}

export function flatten(chapters: IChapter[], startIndex: ChapterIndex = null) {
  if (!chapters || chapters.length == 0) {
    return [];
  }
  let flattened: IChapter[] = [];
  let index = startIndex;

  for (const chap of chapters) {
    let branched = null;
    if (index) {
      chap.index = index;
      branched = index.branched();
      let _subindex = branched;
      if (chap.subchapters && chap.subchapters.length > 0) {
        for (const sub of chap.subchapters) {
          sub.index = _subindex;
          _subindex = _subindex.nextSibling;
        }
      }
    }
    flattened.push(chap);
    if (
      chap.subchapters != undefined &&
      chap.subchapters != null &&
      chap.subchapters.length > 0
    ) {
      flattened = flattened.concat(flatten(chap.subchapters, branched));
    }
    if (index) {
      index = index.nextSibling;
    }
  }

  return flattened;
}

export function flattenIds(
  chapters: IChapter[],
  startIndex: ChapterIndex = null
) {
  let index = startIndex || ChapterIndex.startIndex();

  if (chapters == undefined || chapters.length == 0) {
    return [];
  }

  let flattened: IChapter[] = [];

  for (const chap of chapters) {
    chap.index = index;
    let containsDisplay: string[] = [];
    if (
      !hasSections(chap) &&
      chap.displayMode == ChapterDisplayMode.hierachical &&
      chap.subchapters.length > 0
    ) {
      containsDisplay.push(chap.subchapters[0]._id);
    }
    const flattenedSubChapters = flattenIds(chap.subchapters, index.branched());
    if (
      chap.displayMode != ChapterDisplayMode.hierachical ||
      (chap.subchapters.length > 0 &&
        chap.subchapters[0].displayMode != ChapterDisplayMode.hierachical)
    ) {
      const extra = flattenedSubChapters.map((sc) => sc._id);
      containsDisplay = containsDisplay.concat(extra);
      const extra2 = flattenedSubChapters.map((sc) => sc.containsDisplay);
      for (const cd of extra2) {
        containsDisplay = containsDisplay.concat(cd);
      }
    }

    chap.containsDisplay = containsDisplay;

    if (chap.displayMode != ChapterDisplayMode.hierachical) {
      applyHierarchical(flattenedSubChapters, (p, c) => {
        c.containsDisplay = containsDisplay;
      });
    }

    flattened.push(chap);
    flattened = flattened.concat(flattenedSubChapters);
    index = index.nextSibling;
  }

  return flattened;
}

export function findSurroundingChapter2(
  currentChapterId: string,
  chapters: IChapter[],
  backward: boolean
): IChapter {
  const chapter = findChapterByIdInChapters(currentChapterId, chapters);
  const flattened = flattenIds(chapters);
  const index = flattened.findIndex((c) => {
    return c._id == currentChapterId || c.url_slug == currentChapterId;
  });

  if (index == -1) {
    return null;
  }
  const split = backward
    ? flattened.slice(0, index).reverse()
    : flattened.slice(index + 1);
  if (split.length == 0) {
    return null;
  }
  const firstNonIncluding = split.findIndex((splitc) => {
    return (
      splitc.containsDisplay.findIndex((v) => v == chapter._id) == -1 &&
      flattened[index].containsDisplay.findIndex((v) => v == splitc._id) == -1
    );
  });

  if (firstNonIncluding >= 0) {
    return split[firstNonIncluding];
  } else {
    return null;
  }
}

export function patternForSubChapters(chapter: IChapter): RegExp {
  const matcher = new RegExp(chapter._id.toString() + '(,|$)', 'i');
  return matcher;
}

export function hasSections(chapter: IChapter) {
  return (
    chapter.hasSections || (chapter.sections && chapter.sections.length > 0)
  );
}

export function assignNavigationLinksInChapters2(
  sortedChapters: IChapter[]
): IChapter[] {
  const flattened = sortedChapters;
  let needsNextIndizes: number[] = [];
  let lastFullPageIndex = -1;

  for (let i = 0; i < flattened.length; i++) {
    const chapter = flattened[i];
    (flattened[i] as any).set(
      'previous',
      lastFullPageIndex >= 0 ? flattened[lastFullPageIndex].url_slug : null,
      {
        strict: 'throw'
      }
    );
    const isPage =
      (chapter.displayMode == ChapterDisplayMode.hierachical &&
        hasSections(chapter)) ||
      chapter.displayMode == ChapterDisplayMode.includeChildren;
    if (isPage) {
      lastFullPageIndex = i;
      for (const nn of needsNextIndizes) {
        flattened[nn].next = chapter.url_slug;
        (flattened[nn] as any).set('next', chapter.url_slug, {
          strict: 'throw'
        });
      }
      needsNextIndizes = [];
    }
    needsNextIndizes.push(i);
  }
  return sortedChapters;
}

export function assignNavigationLinksInChapters(
  chapters: IChapter[]
): IChapter[] {
  if (!chapters || chapters.length == 0) {
    return [];
  }
  const flattened = flatten(chapters, ChapterIndex.startIndex());
  let needsNextIndizes: number[] = [];
  let lastFullPageIndex = -1;

  for (let i = 0; i < flattened.length; i++) {
    const chapter = flattened[i];
    (flattened[i] as any).set(
      'previous',
      lastFullPageIndex >= 0 ? flattened[lastFullPageIndex].url_slug : null,
      {
        strict: 'throw'
      }
    );
    const isPage =
      (chapter.displayMode == ChapterDisplayMode.hierachical &&
        hasSections(chapter)) ||
      chapter.displayMode == ChapterDisplayMode.includeChildren;
    if (isPage) {
      lastFullPageIndex = i;
      for (const nn of needsNextIndizes) {
        flattened[nn].next = chapter.url_slug;
        (flattened[nn] as any).set('next', chapter.url_slug, {
          strict: 'throw'
        });
      }
      needsNextIndizes = [];
    }
    needsNextIndizes.push(i);
  }
  applyHierarchical(chapters, (p, chapter) => {
    const f_chapter = flattened.find((c) => {
      return c._id == chapter._id;
    });
    chapter.next = f_chapter.next;
    chapter.previous = f_chapter.previous;
  });
  return chapters;
}

export function assignFullNames(
  chapters: IChapter[],
  startIndex: ChapterIndex,
  columns: IColumn[]
): IChapter[] {
  let index = startIndex;
  for (const chapter of chapters) {
    let branched = null;
    const column = columns.find(
      (c) => (c as any).id.toString() == chapter.columnId.toString()
    );
    if (!column) {
      alert(
        'cant find col with id: ' +
          chapter.columnId +
          'of chapter: ' +
          JSON.stringify(chapter)
      );
    }
    const typeInfo = new ChapterTypeInfo(column);
    if (index) {
      chapter.index = index;
      branched = index.branched();
    } else if (chapter.orderString) {
      chapter.index = new ChapterIndex(
        chapter.orderString.split(',').map((i) => parseInt(i))
      );
      //chapter.fullTitle = chapter.index.description + " " + chapter.title;
    }
    chapter.fullTitle = typeInfo.showChapterIndizes
      ? chapter.index.description + ' ' + chapter.title
      : chapter.title;
    if (chapter.subchapters != undefined && chapter.subchapters.length > 0) {
      chapter.subchapters = assignFullNames(
        chapter.subchapters,
        index ? branched : null,
        columns
      );
    }
    if (index) {
      index = index.nextSibling;
    }
  }
  return chapters;
}

export function findItemInSlugItems<T>(itemId: string, collection: T[]): T {
  const field: string = isValidObjectId(itemId) ? '_id' : 'url_slug';
  if (collection.length == 0) {
    return null;
  }

  for (const item of collection) {
    if (item[field] == itemId) {
      return item;
    }
  }
  return null;
}

export interface PathComponent {
  key: string;
  _id: string;
}

export function findChapterContainingDisplay(
  chapter: IChapter,
  chapters: IChapter[]
): IChapter {
  if (chapter.displayMode == ChapterDisplayMode.includedInParent) {
    const parent = findParentChapterOf(chapter, chapters);

    if (parent) {
      return findChapterContainingDisplay(parent, chapters);
    } else {
      console.error('cant find parent chapter');
      console.error([chapter, flatten(chapters)]);
    }
  }
  return chapter;
}

export function findChapterByIdInChapters(
  chapterId: string,
  chapters: IChapter[],
  recursive = true
): IChapter {
  const field: string = isValidObjectId(chapterId) ? '_id' : 'url_slug';

  if (chapters.length == 0) {
    return null;
  }

  for (const chapter of chapters) {
    if (chapter[field] === chapterId) {
      return chapter;
    }
    if (recursive && chapter.subchapters) {
      const subChapter = findChapterByIdInChapters(
        chapterId,
        chapter.subchapters as IChapter[],
        true
      );
      if (subChapter != null && !(subChapter == undefined)) {
        return subChapter;
      }
    }
  }

  return null;
}

export function findChapterContaining(
  chapterId: string,
  chapters: IChapter[]
): IChapter {
  const field: string = isValidObjectId(chapterId) ? '_id' : 'url_slug';

  if (
    chapters.length == 0 ||
    chapters.findIndex((value) => value[field] == chapterId) > 0
  ) {
    return null;
  }
  for (const chapter of chapters) {
    const subChapter = findChapterByIdInChapters(
      chapterId,
      chapter.subchapters as IChapter[],
      false
    );
    if (subChapter != null && subChapter !== undefined) {
      return chapter;
    } else {
      const chap = findChapterContaining(chapterId, chapter.subchapters);
      if (chap) {
        return chap;
      }
    }
  }

  return null;
}

export function applyHierarchical(
  chapters: IChapter[],
  closure: (parent: IChapter, child: IChapter) => void,
  parent: IChapter = null
) {
  if (!chapters) {
    return;
  }
  for (const chap of chapters) {
    closure(parent, chap);
    applyHierarchical(chap.subchapters, closure, chap);
  }
}

export function findParentChapterOf(
  chapter: IChapter,
  chapters: IChapter[]
): IChapter {
  const flattened = flatten(chapters);
  const parent = flattened.find((chap) => {
    return chap.subchapters.find((sub) => sub._id == chapter._id) !== undefined;
  });
  return parent;
}

export class ChapterIndex {
  public get description(): string {
    return this.values.join('.');
  }

  public get level(): number {
    return this.values.length - 1;
  }

  public static startIndex(): ChapterIndex {
    return new ChapterIndex([1]);
  }

  public indexAtLevel(level: number): number {
    return this.values[level];
  }

  public contains(maybeChild: ChapterIndex): boolean {
    if (maybeChild.level < this.level) {
      return false;
    }

    for (const i in this.values) {
      if (
        this.indexAtLevel(parseInt(i)) != maybeChild.indexAtLevel(parseInt(i))
      ) {
        return false;
      }
    }
    return true;
  }

  private beginAt = 1;

  public enhanced = (level: number, by = 1) => {
    if (level > this.level || level < 0) {
      throw new Error(
        'Tried to enhanceindex at level that is too high or too low '
      );
    }
    const resultValues: number[] = [];

    for (const i in this.values) {
      if (parseInt(i) === level) {
        resultValues[i] = this.values[i] + by;
      } else {
        resultValues[i] = this.values[i];
      }
    }
    return new ChapterIndex(resultValues);
  };

  public get nextSibling(): ChapterIndex {
    return this.enhanced(this.level, 1);
  }

  public get previousSibling(): ChapterIndex {
    const enh = this.enhanced(this.level, -1);
    return enh;
  }

  public get nextChild(): ChapterIndex {
    return this.branched();
  }

  public branched = () => {
    const resultValues: number[] = [];

    for (const i in this.values) {
      resultValues[i] = this.values[i];
    }
    resultValues[this.values.length] = this.beginAt;
    return new ChapterIndex(resultValues);
  };

  public get levels(): number[] {
    return this.values;
  }

  public constructor(private values: number[]) {}
}

export const findChapter = (
  chapters: IChapter[],
  urlSlug: string
): IChapter | null => {
  // TODO: optimize by adding caching
  for (const item of chapters) {
    if (item.url_slug === urlSlug) {
      return item;
    }
    if (item.subchapters?.length) {
      const found = findChapter(item.subchapters, urlSlug);
      if (found) {
        return found;
      }
    }
  }
  return null;
};
