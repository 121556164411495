// feature-flag.service.ts
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {FeatureFlag} from '../models/feature-flags';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  static features = FeatureFlag;

  constructor() {}

  isFeatureEnabled(featureName: FeatureFlag): boolean {
    return environment.features[featureName];
  }
}
