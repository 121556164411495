import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import IUniversityResponse from '@models/university';
import IProfile from '@models/profile';
import { createContentUrl, createProfileUrl } from '@utils/urlFactory';
import { HttpService } from '@core/http';

@Injectable({
  providedIn: 'root'
})
export class UniversityService {
  constructor(private client: HttpService<IUniversityResponse>) {}

  public getAllUniversities = (): Observable<IUniversityResponse[]> => {
    return this.client
      .get<IUniversityResponse[]>(createContentUrl('content', 'universities'))
      .pipe(shareReplay(1));
  };

  public getUniversityByIdForAdmin = (
    universityId: string
  ): Observable<IUniversityResponse> => {
    return this.client.get<IUniversityResponse>(
      createContentUrl('content', 'universities', universityId, 'admin')
    );
  };

  public setMainInstitution(id: string) {
    return this.client.post<IProfile>(createProfileUrl('university', id), null);
  }
}
