import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader.component';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';
import { ElementLoaderComponent } from './components/element-loader/element-loader.component';
@NgModule({
  declarations: [PageLoaderComponent, LoaderComponent, ElementLoaderComponent],
  imports: [CommonModule],
  exports: [LoaderComponent, PageLoaderComponent, ElementLoaderComponent]
})
export class LoaderModule {}
