import {
  AfterContentInit, AfterViewChecked,
  Component, ContentChildren, OnInit,
  QueryList,
  ViewEncapsulation
} from '@angular/core';
import BasicRxComponent from '@components/BasicRxComponent';
import { BehaviorSubject } from 'rxjs';
import { SectionBottomTabComponent } from '@components/section/components/tabs/tab/section-bottom-tab.component';
import { SectionBottomTab } from '@components/section/components/tabs/tab/section-bottom-tab.types';
import { Router } from '@angular/router';

@Component({
  selector: 'app-section-bottom-tabs',
  templateUrl: './section-bottom-tabs.component.html',
  styleUrls: ['./section-bottom-tabs.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class SectionBottomTabsComponent
  extends BasicRxComponent
  implements AfterContentInit, OnInit, AfterViewChecked {
  @ContentChildren(SectionBottomTabComponent) tabComponents!: QueryList<SectionBottomTabComponent>;

  public tabs: SectionBottomTab[] = [];
  public activeTab$ = new BehaviorSubject<number>(null);

  constructor(
    private router: Router,
  ) {
    super();
  }

  get desktopTabWidths() {
    return this.tabs.reduce((result, tab) => {
      result.push(tab.desktopTitleWidth)
      return result;
    }, []).join(' ')
  }

  selectTab(index: number) {
    if (this.tabs[index].content) {
      if (this.activeTab$.getValue() === index) {
        this.activeTab$.next(null)
      } else {
        this.activeTab$.next(index);
      }
    }
  }

  public syncTabs() {
    this.tabs = this.tabComponents.toArray();
    if (this.activeTab$.getValue() >= this.tabs.length) {
      this.reset();
    }
  }

  ngAfterContentInit() {
    this.syncTabs();
  }

  ngOnInit() {
    this.bag.add(this.router.events.subscribe(() => {
      // necessary to close html element, which is kept when navigating between chapters (same route, different param)
      this.reset()
    }));
  }

  ngAfterViewChecked(): void {
    // If tabs are added or removed async, we won't get the change via Init-Methods
    if (this.tabComponents.length !== this.tabs.length) {
      this.syncTabs();
    }
  }

  private reset() {
    this.activeTab$.next(null);
  }
}
