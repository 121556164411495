/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

export interface IRouterService {
  queryParams: BehaviorSubject<any>;
  currentRoute: string;
  updateQueryParams(p: any): void;
  init(): void;
}

@Injectable({
  providedIn: 'root'
})
export class RouterService implements IRouterService {
  public queryParams: BehaviorSubject<Record<string, any>> = new BehaviorSubject({});

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  get currentRoute() {
    return this.router.url
  }

  updateQueryParams(p: Record<string, any>) {
    const navigationExtras: NavigationExtras = {
      queryParams: { ...this.queryParams.getValue(), ...p },
      queryParamsHandling: 'merge'
    };
    const currentUrlSegment = this.router.url.split('?')[0].split('/');
    this.router.navigate(currentUrlSegment, navigationExtras);
  }

  init() {
    this.route.queryParams.subscribe(this.queryParams);
  }
}

