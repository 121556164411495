<div class="disable-chatbot">
  <i class="fas fa-question-circle"
     [matTooltip]="'Unabhängig der Column-Einstellung kann der Chatbot für ausgewählte Kapitel und Abschnitte deaktiviert werden'"
  ></i>
  Chatbot deaktiviert:
  <app-button-group>
    <button class="btn primary" [matTooltip]="'Der Chatbot wird nicht deaktiviert'" [ngClass]="{
        outline: chatbotDisabledBy
      }" (click)="setChatbotDisabled(ChatbotDisabledBy.NotDisabled)">
      Nicht deaktiviert
    </button>
    <button class="btn primary"
            [matTooltip]="'Der Chatbot wird deaktiviert, solange bis die Datenqualität automatisch als ausreichend erkannt wird'"
            [ngClass]="{
                outline: chatbotDisabledBy !== ChatbotDisabledBy.DataQualityDetection
              }" (click)="setChatbotDisabled(ChatbotDisabledBy.DataQualityDetection)">
      Temporär deaktiviert
    </button>
    <button class="btn primary"
            [matTooltip]="'Der Chatbot wird dauerhaft deaktiviert'"
            [ngClass]="{
                outline: chatbotDisabledBy !== ChatbotDisabledBy.ManualInterference
              }" (click)="setChatbotDisabled(ChatbotDisabledBy.ManualInterference)">
      Dauerhaft deaktiviert
    </button>
  </app-button-group>
</div>
