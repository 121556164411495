import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import ISection, { ICustomSectionStyle, SectionStyleCustomClasses } from '@models/section';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { MatOption, MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-custom-styles',
  templateUrl: './custom-styles.component.html',
  styleUrls: ['./custom-styles.component.scss'],
  imports: [
    MatTooltip,
    NgClass,
    NgIf,
    MatSelect,
    MatOption,
    NgForOf
  ],
  standalone: true
})
export class CustomStylesComponent implements OnChanges, OnInit {
  @Input({required: true}) public section: ISection = null;
  @Output() private onChange = new EventEmitter<ICustomSectionStyle[]>();

  public unselectedStyles: ICustomSectionStyle[] = [];

  public get selectedStyles(): ICustomSectionStyle[] {
    return this.section?.customStyles || [];
  }

  public get allStyles(): ICustomSectionStyle[] {
    return Object.values(SectionStyleCustomClasses).map((value) => ({
      type: 'class',
      value
    }));
  }

  public getName(style: ICustomSectionStyle): string {
    return {
      [SectionStyleCustomClasses.NoBackground]: 'Kein Hintergrund',
    }[style.value] || style.value
  }

  public getTooltip(style: ICustomSectionStyle): string {
    return {
      [SectionStyleCustomClasses.NoBackground]: 'Zeigt den Abschnitt ohne Hintergrund an',
    }[style.value]
  }

  public addStyle(value: string) {
    const style = this.unselectedStyles.find((style) => style.value === value);
    if (!style) {
      return;
    }

    const newStyles = [...this.selectedStyles];
    newStyles.push(style);
    this.onChange.emit(newStyles);
  }

  public removeStyle(style: ICustomSectionStyle) {
    this.onChange.emit(this.selectedStyles.filter((selected) =>
      !(selected.type === style.type && selected.value === style.value)
    ));
  }

  ngOnChanges(): void {
    this.updateUnselectedStyles();
  }

  ngOnInit(): void {
    this.updateUnselectedStyles();
  }

  // Angular runs into an infinite loop when rendering this as a getter, so we have to store it beforehand
  private updateUnselectedStyles() {
    this.unselectedStyles = this.allStyles
      .filter((style) =>
        !this.selectedStyles.find((selected) => selected.value === style.value)
      );
  }
}
