<!-- https://codepen.io/Launghe/pen/pozrwqg -->
<!-- <div class="loading-screen">
  <div class="loader__content">
    <div class="loader__spinner"></div>
    <p clas="loader__text">Einen Moment...</p>
  </div>
</div> -->

<div class="loading-screen">
  <div>
    <div class="spinner">
      <!-- hm, loading of the page won't initialize fa -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        class=""
      >
        <!-- Font Awesome Pro 6.0.0-alpha1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
        <defs>
          <style>
            .fa-secondary {
              opacity: 0.4;
            }
          </style>
        </defs>
        <path
          d="M471 361C432 441 351 496 256 496C123 496 16 389 16 256S123 16 256 16C261 16 266 16 271 17C263 17 256 24 256 33V65C256 73 263 80 271 81C272 81 272 81 272 81C267 80 261 80 256 80C159 80 80 159 80 256S159 432 256 432C327 432 388 390 416 330C416 330 415 330 415 330C412 338 414 347 421 352L449 368C457 372 467 369 471 361Z"
          class="fa-secondary"
        />
        <path
          d="M496 256C496 294 487 329 472 361C468 369 457 372 449 368L421 352C414 347 412 338 415 330C426 308 432 283 432 256C432 164 361 88 271 81C263 80 256 73 256 65V33C256 24 264 16 273 17C398 25 496 129 496 256Z"
          class="fa-primary"
        />
      </svg>
    </div>
    <div class="lock">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <!-- Font Awesome Pro 6.0.0-alpha1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
        <path
          d="M416 224H384V128C384 57 327 0 256 0S128 57 128 128V224H96C61 224 32 253 32 288V448C32 483 61 512 96 512H416C451 512 480 483 480 448V288C480 253 451 224 416 224ZM144 128C144 66 194 16 256 16S368 66 368 128V224H144V128ZM464 448C464 474 442 496 416 496H96C70 496 48 474 48 448V288C48 262 70 240 96 240H416C442 240 464 262 464 288V448ZM256 320C238 320 224 334 224 352V384C224 402 238 416 256 416S288 402 288 384V352C288 334 274 320 256 320ZM272 384C272 393 265 400 256 400S240 393 240 384V352C240 343 247 336 256 336S272 343 272 352V384Z"
        />
      </svg>
    </div>
    <div class="text">Einen Moment...</div>
  </div>
</div>
