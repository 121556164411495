import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';


@Component({
  selector: 'app-bookmark',
  standalone: true,
  template: `
      <span class="bookmark" [ngClass]="{
          bookmarked: bookmarked,
          loading: loading,
        }" tabindex="0" (click)="toggle()" (keydown.enter)="toggle()" (keydown.space)="toggle()">
          <i class="fa-bookmark current-state" [ngClass]="{ 'fa-regular': !bookmarked, 'fa-solid': bookmarked, }"></i>
          <i class="fa-bookmark next-state" [ngClass]="{ 'fa-regular': bookmarked, 'fa-solid': !bookmarked, }"></i>
        </span>
  `,
  imports: [
    AsyncPipe,
    NgIf,
    NgClass,
  ],
  styleUrls: ['bookmark.component.scss'],
})
export class BookmarkComponent {
  @Input() bookmarked: boolean = false;
  @Input() loading: boolean = false;

  @Output() onBookmark = new EventEmitter<void>();
  @Output() onUnBookmark = new EventEmitter<void>();

  public toggle() {
    if (this.loading) {
      return;
    }
    if (this.bookmarked) {
      this.onUnBookmark.emit();
    } else {
      this.onBookmark.emit();
    }
  }
}
