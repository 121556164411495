/**
 * Created by huck on 15.06.18
 */
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ICourse } from '@models/course';

@Component({
  selector: 'course-preview',
  templateUrl: './coursepreview.component.html',
  styleUrls: ['./coursepreview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoursePreviewComponent {
  @Input() public course: ICourse;
}
