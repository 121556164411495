import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import BasicRxComponent from '@components/BasicRxComponent';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-page-loader',
  template: `
    <ng-template [ngIf]="(loading | async) === true">
      <div class="loader-wrapper">
        <app-loader></app-loader>
      </div>
    </ng-template>
    <ng-template [ngIf]="(loading | async) === false">
      <ng-content></ng-content>
    </ng-template>
  `,
  styleUrls: ['./page-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated
})
export class PageLoaderComponent extends BasicRxComponent implements OnInit {
  @Input() loading: Observable<boolean> = of(false);

  @HostBinding('class.loading') loadingClass: boolean;

  ngOnInit() {
    this.bag.add(this.loading.subscribe((v) => (this.loadingClass = v)));
  }
}
