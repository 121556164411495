
export function formatAiMessage(message: string) {
  return message
    .replace(/```/g, '')
    .replace(/(\\\(|\\\))/g, '$')
    .replace(/(\\\[|\\\])/g, '$$$') // for some reason the replacement is $$
    .split('$$')
    .map((part, i) => {
      if (i % 2) {
        // math block
        return '<br><span class="before-math-block"></span>$$' + part
            .replace(/</g, ' \\lt ')
            .replace(/>/g, ' \\gt ')
            .replace(/\*/g, '\\ast ')
          + '$$<span class="after-math-block"></span><br>'
          ;
      } else {
        // text and inline math
        return part
          .replace(/([^$]*)(\$[^$]*\$)?/gm, (fullMatch, text, latex) => {
            // Text may include markdown formatting
            const newText = (text || '')
              .replace(/</g, '&lt;')
              .replace(/>/g, '&gt;')
              .replace(/^ +$/gm, '')
              .replace(/ {2}/g, '&nbsp;&nbsp;')
              .replace(/\n/g, '<br>\n')
            ;
            const newLatex = (latex || '')
              .replace(/</g, ' \\lt ')
              .replace(/>/g, ' \\gt ')
              .replace(/\*/g, '\\ast ')
            ;
            return newText + newLatex
          })
          .replace(/(\n|^) *#+ *([^\n]*<br>*\n)/gm, (match, begin, content) => {
            return `<h5>${content}</h5>`
          })
          .replace(/\*\*([^*]+)\*\*/g, (match, content) => {
            return `<strong>${content}</strong>`
          });
      }
    })
    .join('')
    .split(/<br>\n? *<br>\n?/g)
    .filter((part) => !part.match(/^ *<br> *\n *$/))
    .map((part, i, parts) => {
      let result = part.replace(/^ *\n? *<br>/, '');
      if (i === 0) {
        result = '<p>' + result;
      }
      if (i === parts.length - 1) {
        result = result + '</p>';
      }
      return result;
    })
    .join('</p><p>')
    .replace(/( *<br>\n*){2,}/gm, ' <br>\n')
}
