export interface IVideoSource {
  url: string;
}

export interface IVideoPlayer {
  videoSource: IVideoSource;
}

export enum PLAYER_STATE_TYPES {
  INIT,
  PLAYING,
  PAUSE
}

export class PlayerStateChangeAction<T> {
  constructor(public type: PLAYER_STATE_TYPES, public payload?: T) {}
}

export interface IVideoPlayerControllable {
  isPlaying: boolean;
  play: () => void;
  pause: () => void;
  stop: () => void;
}
