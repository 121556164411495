import { env, FullEnvironment } from './environment.common';

const baseUrl = 'https://devspa.ecoreps.huck-it.de/';
const API_URL = 'https://dev.ecoreps.huck-it.de/v2';
const API_INGESTION = 'https://dev.ecoreps.huck-it.de/v2';
export const environment: FullEnvironment = {
  ...env,
  baseUrl,
  production: false,
  API_URL,
  API_URL_PROFILE: API_URL,
  API_NOTIFICATION: API_URL,
  API_CHATBOT: API_URL,
  API_MEDIA: API_URL,
  logoutUrl: baseUrl,
  loginCallback: baseUrl,
  landingPageUrl: baseUrl,
  auth0: {
    ...env.auth,
    redirectUri: baseUrl
  },
  paypal: {
    ...env.paypal
  },
  paypalKKL: {
    clientId:
      'AbsP6mHP4DTgSijbJJs--WGeNPuhoOpI0PSj1TTM2HTy34YX-4AHKguvD9ru5I0krD02DR9P7CEVWoc7'
  },
  features: {
    search: true,
    mediaComments: true,
    userdataService: true,
  }
};
