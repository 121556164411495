const TEST_ID_PREFIX = 'top-bar';

export const TEST_IDS = {
  COURSES: `${TEST_ID_PREFIX}-courses-link`,
  CONTACTS: `${TEST_ID_PREFIX}-contacts`,
  ABOUT_US: `${TEST_ID_PREFIX}-about-us`,
  MY_COURSES_ANONYMOUS: `${TEST_ID_PREFIX}-my-courses-anonymous`,
  LOGIN_BTN: `${TEST_ID_PREFIX}-login-btn`,
  REGISTER_BTN: `${TEST_ID_PREFIX}-register-btn`,
  TOGGLE: `${TEST_ID_PREFIX}-toggle-btn`,
  MY_COURSES: `${TEST_ID_PREFIX}-my-courses-link"`
};
