<div class="root app-dialog" [class.closable]="closable">
  <div
    class="backdrop"
    [class.backdrop_fill]="backdropBackground === 'fill'"
    [class.backdrop_transparent]="backdropBackground === 'transparent'"
    (click)="onBackdropClick()"
  ></div>
  <div
    class="content"
    [class.content_top]="contentAlign=== 'top'"
    [class.content_center]="contentAlign === 'center'"
  >
    <div class="head">
      <h3 [class]="titleClassName" [class.title_align-left]="titleAlign === 'left'">
        {{title}}
      </h3>
      <div *ngIf="closable" (click)="onCloseClick()" class="close">
        <i class="fas fa-times"></i>
      </div>
    </div>
    <div class="body">
      <ng-content></ng-content>
    </div>
    <div class="actions">
      <button
        *ngIf="submittable"
        (click)="accept()"
        class="{{getBtnClass(submitBtnVariant) + ' submit'}}"
        [class.disabled]="!submittable"
      >
        {{submitText}}
      </button>
      <button
        *ngIf="cancelable"
        (click)="onCancelClick()"
        class="{{getBtnClass(cancelBtnVariant) + ' cancel'}}"
      >
        {{cancelText}}
      </button>
    </div>
    <div class="hint">
      <ng-content select="[hint]"></ng-content>
    </div>
  </div>
</div>
