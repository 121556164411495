<div class="section-bottom-tabs" [style]="{
    '--desktop-tab-widths': desktopTabWidths,
}">
  <ng-container *ngFor="let tab of tabs; let i = index">
    <div class="section-bottom-tabs__tab-title"
         [ngClass]="{
          active: i === (activeTab$ | async),
          'has-content': tab.content,
         }"
         (click)="selectTab(i)"
    >
      <div class="section-bottom-tabs__tab-title_content">
        <ng-container *ngTemplateOutlet="tab.title"></ng-container>
      </div>
      <div *ngIf="tab.content" class="section-bottom-tabs__tab-title_toggle-icon">
        <i class="fas fa-chevron-down icon"></i>
      </div>
    </div>
  </ng-container>
  <div *ngIf="(activeTab$ | async) !== null" class="section-bottom-tabs__tab-content" [style]="{
    '--desktop-row': (activeTab$ | async) + 2,
  }">
    <ng-container *ngTemplateOutlet="tabs[activeTab$ | async]?.content"></ng-container>
  </div>
</div>
