<div class="progress-bar-component">
	<div class="number">{{(progress || 0) * 100 | number:'1.0-0'}}%</div>
	<div class="bars">
		<div
			class="progress"
			[style.width.%]="progress * 100 | number:'1.0-0'"
			*ngIf="progress"
		></div>
		<div
			class="average"
			[style.width.%]="average * 100 | number:'1.0-0'"
			*ngIf="average"
		></div>
		<div
			class="cut"
			*ngIf="cut"
			[style.left.%]="cut * 100 | number:'1.0-0'"
		></div>
	</div>
</div>
