<div class="text-section">
  <div class="text-section__comment-text comment-text">
    <span class="comment-text__title">{{title}} </span>
    <span
      class="comment-text__content"
      #commentText
      [innerHtml]="comment.text | sanitizeHtml: 'html'"
      [class.hidden]="commentToEdit?.id === comment._id"
    ></span>
    <textarea
      #commentTextArea
      class="textarea comment-text__textarea"
      [(ngModel)]="commentToEdit.text"
      [class.visible]="commentToEdit?.id === comment._id"
    >
    </textarea>
    <ng-container *ngIf="comment.media?.length">
      <div class="comment-text__players-wrapper">
        <div
          *ngFor="let media of comment.media "
          class="comment-text__video-container"
        >
          <app-media-content [media]="media"></app-media-content>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="text-section__controls" *ngIf="editMode">
    <button
      title="Send updated text"
      *ngIf="commentToEdit?.id === comment._id"
      class="btn outline primary"
      (click)="send()"
    >
      <span class="fa fa-envelope"></span>
    </button>
    <button
      class="btn outline primary"
      title="Edit comment"
      *ngIf="editCommentAllowed$ | async"
      (click)="toggleEdit(comment)"
    >
      <span class="fa fa-pencil"></span>
    </button>
    <button
      *ngIf="deleteCommentAllowed$ | async"
      title="Delete comment"
      class="btn outline danger"
      (click)="remove()"
      [disabled]="commentToEdit?.id === comment._id"
    >
      <span class="fa fa-trash"></span>
    </button>
  </div>
</div>
