import { Injectable } from '@angular/core';
import { ILocalStorageService } from './types';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService implements ILocalStorageService {
  constructor() {}

  getItem(key: string): string {
    try {
      return sessionStorage.getItem(key);
    } catch (e) {
      return null;
    }
  }
  setItem(key: string, value: string): void {
    try {
      sessionStorage.setItem(key, value);
    } catch (e) {
      return null;
    }
  }
  removeItem(key: string): void {
    try {
      sessionStorage.removeItem(key);
    } catch (e) {
      return null;
    }
  }
  clear(): void {
    sessionStorage.clear();
  }
}
