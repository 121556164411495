export const MODAL_IDS = {
  ANONIMOUS_COURSE_MODAL: 'anonymous-course-modal',
  BUY_COURSE: 'buy-course-modal',
  REGISTER: 'register-modal',
  LEARNING_OVERVIEW: 'learning-overview-modal',
  SELECT_INSTITUTION: 'select-institution-modal',
  EXAM_ENROLMENT: 'exam-enrollment-modal',
  RESULT_MODAL: 'result-modal',
  FEEDBACK_SUCCESS: 'feedback-success-modal',
  VIDEO_PREVIEW: 'video-preview',
  WRONG_CAPTCHA: 'wrong-captcha',
  CONTACT_FORM_INVALID: 'contact-form-invalid',
  CONTACT_FORM_SUBMIT_FAIL: 'contact-form-sumbit-fail',
  COMMENTS_ADD_SUCCESS_SUBMIT: 'comments-success-submit',
  COMMENTS_ADD_ERROR: 'comments-add-error',
  REVIEW_DETAILS_MODAL: 'review-details-modal',
  SELF_REACTIVATION_HELP_MODAL: 'self-reactivation-help-modal',
  DYNAMIC_MODAL: 'dynamic-modal',
  PHOTO_PREVIEW: 'photo-preview',
  NOTIFICATION_SEND_MAILS: 'notification-send-mails',
  SECTION_EDITOR: 'section-editor-modal',
  SECTION_EDITOR_CHANGE: 'section-editor-change-modal',
  SECTION_CHAPTER_IMPORT_MODAL: 'section-chapter-import-modal',
  SELF_REACTIVATION_WITH_UNDECIDED_ENROLLMENT: 'self-reactivation-with-undecided-enrollment-modal',
  SESSION_EXPIRED: 'session-expired',
  ADMIN_ENROLLMENT_FORM: 'admin-enrollment-form',
  TRANSCRIBE_COURSE: 'transcribe-course',
};
