import {
  Component,
  Injectable,
  Input,
  ViewEncapsulation
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'ecospinner',
  styleUrls: ['./ecospinner.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  template: `
    <div *ngIf="isLoading | async" class="ecospinner">
      <span class="font-family:arial"
        >ec<img
          src="assets/images/circle.png"
          class="rotating"
          alt="circle"
          width="120"
          height="120"
        />reps
      </span>
    </div>
  `
})
export class EcospinnerComponent {
  @Input() public isLoading: Observable<boolean> = of(true);
  @Input() public fullScreen = false;
}

@Injectable({
  providedIn: 'root'
})
export class EcoSpinnerService {
  constructor(private overlay: Overlay) {}

  open(): OverlayRef {
    // Returns an OverlayRef (which is a PortalHost)

    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-dark-backdrop',
      panelClass: 'tm-file-preview-dialog-panel',
      positionStrategy: positionStrategy
    });

    // Create ComponentPortal that can be attached to a PortalHost
    const spinner = new ComponentPortal(EcospinnerComponent);

    // Attach ComponentPortal to PortalHost
    overlayRef.attach(spinner);
    return overlayRef;
  }
}
