import { BehaviorSubject, Subject } from 'rxjs';

export enum MediaType {
  VIDEO = 'video',
  AUDIO = 'audio'
}

export interface IMediaUploader {
  upload(file: Blob): Promise<IUploadResult | IUploadError>;
}

export interface IMediaGetter {
  getMedia(mediaId: string): Promise<IVimeoVideoUploadResponse>;
}

export type UploadResult = IUploadResult | IUploadError | undefined;

export interface IMediaRecorder extends IMediaGetter {
  startRecord(): Promise<Subject<UploadResult>>;
  stopRecord(): void;
  recordStatus$: BehaviorSubject<RecordStatus>;
  error$: Subject<Error | null>;
  uploadResult$: Subject<UploadResult>;
}

export interface IMediaCapturer extends IMediaRecorder, IMediaGetter {}

export type RecordStatus =
  | 'none'
  | 'inprogress'
  | 'upload'
  | 'error'
  | 'success';

export interface IUploadResult {
  type: 'video' | 'audio';
  publicLink?: string;// Link containing the link to vimeo file directly, such as http://vimeo.com/12345667
  link: string;
  id: string;
}

export interface IUploadError {
  message: string;
  success: boolean;
}

export type VimeoUploadStatus =
  | 'transcode_starting'
  | 'transcoding'
  | 'transcode'
  | 'available'
  | string;

export interface IVimeoVideoUploadResponse {
  uri: string;
  name: string;
  type: string;
  link: string;
  player_embed_url: string;
  duration: number;
  width: number;
  height: number;
  rating_mod_locked: boolean;
  license: string | null;
  tags: string[];
  categories: string[];
  parent_folder: null;
  status: VimeoUploadStatus;
  upload: IUpload;
  resource_key: string;
  is_playable: boolean;
  has_audio: boolean;
}

interface IUpload {
  status: 'transcode_starting' | 'available' | string;
  upload_link: string;
  approach: string;
  size: number;
  redirect_url: string | null;
  link: string | null;
}

export function isUploadError(result: UploadResult): result is IUploadError {
  return (result as IUploadError).success === false;
}
