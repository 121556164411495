import { Subscription } from 'rxjs';

export class DisposeBag {
  private _subscriptions: Subscription[] = [];

  public add(subscription: Subscription) {
    this._subscriptions.push(subscription);
  }

  public addSubscriptions(subscriptions: Subscription[]) {
    this._subscriptions = this._subscriptions.concat(subscriptions);
  }

  public dispose() {
    for (const sub of this._subscriptions) {
      sub.unsubscribe();
    }
    this._subscriptions = [];
  }
}
