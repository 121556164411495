<div class="part footer">
	<div class="content">
		<div class="links">
			<a routerLink="/offers">Kursangebot</a>
			<a routerLink="/contact">Kontakt</a>
			<a routerLink="/imprint">Impressum</a>
			<a routerLink="/privacy-policy">Datenschutz</a>
		</div>
		<div class="copyright">
			&copy; {{year}}
			<span routerLink="/home">{{ content.copyright }}</span>
		</div>
	</div>
</div>
