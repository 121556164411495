<ng-container>
  <div class="backdrop" [class.open]="open" (click)="open = false"></div>

  <div
    class="app-nav part"
    [class.scrolled]="(opaqueNavBar | async)"
    [class.fade-top]="(hideNavBar | async)"
    [class.open]="open"
  >
    <div class="content">
      <div class="logo-wrapper">
        <img
          routerLink="/"
          [src]="logoUrl()"
          class="logo"
          (click)="toggleNav(false)"
        />
        <img
          routerLink="/"
          [src]="logoUrlNaked()"
          class="logo white"
          *ngIf="logoUrlNaked()"
          (click)="toggleNav(false)"
        />
      </div>
      <div class="menu-wrapper">
        <div class="toggle" [attr.data-test-id]="TEST_IDS.TOGGLE" (click)="toggleNav()">
          <img *ngIf="!open" src="./assets/studyprime/svg/bars.svg" />
          <img *ngIf="open" src="./assets/studyprime/svg/times.svg" />
        </div>
        <div class="menu">
          <a
            *ngIf="!(isLoggedIn | async) || (loginService.isAnonymous | async)"
            routerLink="/mycourses"
            class="trial btn large outline accent light"
            [attr.data-test-id]="TEST_IDS.MY_COURSES_ANONYMOUS"
          >
            Kostenlos testen
          </a>
          <a
            routerLink="/about-us"
            (click)="toggleNav(false)"
            [attr.data-test-id]="TEST_IDS.ABOUT_US"
            >Über Uns</a
          >
          <a
            routerLink="/offers"
            (click)="toggleNav(false)"
            [attr.data-test-id]="TEST_IDS.COURSES"
            >Kursangebot</a
          >
          <a
            routerLink="/contact"
            (click)="toggleNav(false)"
            [attr.data-test-id]="TEST_IDS.CONTACTS"
            >Kontakt</a
          >

          <div class="divider" [class.safari-fix]="isSafari"></div>
          <ng-container
            *ngIf="(isLoggedIn | async) && !(loginService.isAnonymous | async)"
          >
            <a
              routerLink="/mycourses"
              class="my-courses-link btn accent outline"
              [data-test-id]="TEST_IDS.MY_COURSES"
            >
              Mein Kursbereich
            </a>
            <a
              (click)="logout()"
              class="logout"
            >
              Logout
            </a>
            <app-profile-dropdown class="top-bar__profile-dropdown"></app-profile-dropdown>

          </ng-container>
          <ng-container
            *ngIf="!(isLoggedIn | async) || (loginService.isAnonymous | async)"
          >
            <a
              (click)="login()"
              class="login"
              [attr.data-test-id]="TEST_IDS.LOGIN_BTN"
              >Login</a
            >
            <a
              (click)="register()"
              class="register btn large primary"
              [attr.data-test-id]="TEST_IDS.REGISTER_BTN"
              >Registrieren</a
            >
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
