import { createMediaUrl } from '@utils/urlFactory';
import {
  IMediaGetter,
  IUploadResult,
  IVimeoVideoUploadResponse
} from './types';
import { IMediaUploader } from './types';
import { HttpService } from '@core/http';

export class VideoService implements IMediaUploader, IMediaGetter {
  constructor(private httpService: HttpService<IUploadResult>) {}

  async upload(file: Blob): Promise<IUploadResult> {
    // Ensure the file parameter is a Blob or File object
    if (!(file instanceof Blob)) {
      console.error('The provided file is not a Blob or File.');
      return;
    }
    const formData = new FormData();
    formData.append('file', file, 'video.mp4');
    return new Promise<IUploadResult>((res, rej) => {
      this.httpService
        .post<IUploadResult>(
          createMediaUrl('videos', 'upload'),
          formData
        )
        .subscribe(res, rej);
    });
  }

  async getMedia(videoId: string): Promise<IVimeoVideoUploadResponse> {
    return new Promise((res, rej) => {
      this.httpService
        .get<IVimeoVideoUploadResponse>(
          createMediaUrl('videos', videoId),
        )
        .subscribe(res, rej);
    });
  }
}
