import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import {
  ChapterPriority,
  IChapter,
  ICreateChapterModel,
  IMoveChapterModel,
  IUpdateChapterModel,
  PreviewMode
} from '@models/chapter';
import { ICourse } from '@models/course';
import { LoginService } from '@core/auth/login.service';
import { map, share } from 'rxjs/operators';
import { applyHierarchical, ChapterIndex } from '@utils/chapterUtils';
import ISection from '@models/section';
import {
  ICompletionRequest,
  IProgressChapterEntry,
  ProgressState
} from '@models/profile';
import { HttpService } from '@core/http';
import {createContentUrl, createProfileUrl} from '@utils/urlFactory';
import {ILatexErrorResponse} from '@models/content/latex-request';
import { ChapterImportStrategy } from '@models/content/import';

@Injectable({
  providedIn: 'root'
})
export class ChapterService {
  constructor(
    private client: HttpService<IChapter>,
    private loginService: LoginService
  ) {}

  public isChapterForbidden(chapter: IChapter, course: ICourse): boolean {
    if (course.isPurchased) {
      return false;
    }

    const allowedModes = [PreviewMode.publicMode];

    if (!this.loginService.isAnonymous.getValue()) {
      allowedModes.push(PreviewMode.membersMode);
    }
    return !(chapter.inPreview && allowedModes.includes(chapter.previewMode));
  }

  public isChapterInMembersPreviewOnly(
    chapter: IChapter,
    course: ICourse
  ): boolean {
    if (course.isPurchased) {
      return false;
    }

    return chapter.inPreview && chapter.previewMode == PreviewMode.membersMode;
  }

  public getChapterById = (
    chapterId: string,
    forceReload = false,
    isCustomErrorHandling?: (error: HttpErrorResponse) => boolean
  ): Observable<IChapter> => {
    const cachedChapter = this.client.getCachedEntity(chapterId);

    if (cachedChapter && !forceReload) {
      return of(cachedChapter);
    } else {
      const url = createContentUrl('chapters', chapterId);
      return this.handleChapterFetch(url, isCustomErrorHandling);
    }
  };

  private handleChapterFetch(url: string, isCustomErrorHandling?: (error: HttpErrorResponse) => boolean) {
    return this.client
      .get<IChapter>(url, undefined, isCustomErrorHandling)
      .pipe(
        map((chapter) => {
          chapter.index = new ChapterIndex(
            chapter.orderString.split(',').map((i) => parseInt(i))
          );

          applyHierarchical(chapter.subchapters, (_, chap) => {
            chap.index = new ChapterIndex(
              chap.orderString.split(',').map((i) => parseInt(i))
            );
          });
          return chapter;
        }),
        map((chapter) => {
          this.client.addToCache(chapter);
          return chapter;
        }),
        share()
      );
  }

  public getAdminChapterById(
    chapterId: string,
    isCustomErrorHandling?: (error: HttpErrorResponse) => boolean,
  ) {
    const url = createContentUrl('chapters', chapterId, 'admin');
    return this.handleChapterFetch(url, isCustomErrorHandling);
  }

  public addChapter(
    chapterCreationModel: ICreateChapterModel,
    course: ICourse
  ): Observable<IChapter> {
    const url = createContentUrl('courses', course._id, 'chapters');
    return this.client.post<IChapter>(url, chapterCreationModel);
  }

  public addChapterSection = (
    section: ISection,
    courseId: string,
    chapterId: string
  ): Observable<IChapter> => {
    this.client.deleteCachedEntity(chapterId);
    const url = createContentUrl(
      'courses',
      courseId,
      'chapters',
      chapterId,
      'sections'
    );

    return this.client.post<IChapter>(url, section);
  };

  public updateChapterSection = (
    section: ISection,
    courseSlug: string,
    chapterSlug: string
  ): Observable<IChapter> => {
    this.client.deleteCachedEntity(chapterSlug);
    return this.client.put<IChapter>(
      createContentUrl('courses', courseSlug, 'chapters', chapterSlug, 'sections'),
      section
    );
  };

  public deleteChapterSection = (
    sectionId: string,
    courseId: string,
    chapterId: string
  ): Observable<IChapter> => {
    this.client.deleteCachedEntity(chapterId);
    return this.client.delete<IChapter>(
      createContentUrl(
        'courses',
        courseId,
        'chapters',
        chapterId,
        'sections',
        sectionId
      )
    );
  };

  public deleteChapter(chapter: IChapter, courseSlug: string): Observable<boolean> {
    return this.client.delete<boolean>(
      createContentUrl('courses', courseSlug, 'chapters', chapter._id)
    );
  }

  public updateChapter(chapter: IChapter, courseId: string) {
    const body: IUpdateChapterModel = {
      _id: chapter._id,
      title: chapter.title,
      displayMode: chapter.displayMode,
      priority: chapter.priority || ChapterPriority.normal,
      previewMode: chapter.previewMode,
      chatbotDisabledBy: chapter.chatbotDisabledBy,
    };
    this.client.deleteCachedEntity(chapter._id);
    return this.client.put(
      createContentUrl('courses', courseId, 'chapters', chapter._id),
      body
    );
  }

  public moveChapter(
    courseId: string,
    moveModel: IMoveChapterModel
  ): Observable<ICourse> {
    const body = moveModel;
    const chap = body.chapterId;

    delete body.chapterId;

    return this.client.post<ICourse>(
      createContentUrl('courses', courseId, 'chapters', chap, 'move'),
      body
    );
  }

  public completeChapter = (
    state: ProgressState,
    course: ICourse,
    chapterId: string,
    sectionId?: string
  ): Observable<IProgressChapterEntry> => {
    const req: ICompletionRequest = {
      sectionId,
      chapterId,
      state
    };
    const url = createProfileUrl('progress', course.url_slug);

    return this.client.post<IProgressChapterEntry>(url, req);
  };

  public exportChapterToLatex = (chapter: IChapter, courseId: string): Observable<Blob> => {
    return this.client.post(
      createContentUrl('courses', courseId, 'chapters', chapter._id, 'export'),
      {},
      {
        responseType: 'blob' as 'json',
      }
    );
  }

  public importSection = (sectionId: string, courseId: string, chapterId: string): Observable<IChapter> => {
    this.client.deleteCachedEntity(chapterId);
    return this.client.post(
      createContentUrl('courses', courseId, 'chapters', chapterId, 'sections', sectionId, 'import'),
      {},
    );
  }

  public importChapter = (chapterToImportId: string, courseId: string, chapterId: string, strategy: ChapterImportStrategy): Observable<IChapter> => {
    return this.client.post(
      createContentUrl('courses', courseId, 'chapters', chapterId, 'import', chapterToImportId),
      {
        strategy,
      },
    );
  }

  public importChapterFromLatex = (chapter: IChapter, courseId: string, latex: string): Observable<{chapter: IChapter, errors: ILatexErrorResponse[]}> => {
    return this.client.post(
      createContentUrl('courses', courseId, 'chapters', chapter._id, 'import'),
      { latex },
    );
  }
}
