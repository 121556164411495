import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface ITopbarNavigation {
  show: boolean;
  logo?: string;
  showFreeTrial?: boolean;
}

const INITIAL_VALUE = {
  show: true
};

@Injectable({
  providedIn: 'root'
})
export class TopbarContextService {
  public context: BehaviorSubject<ITopbarNavigation | null> =
    new BehaviorSubject(INITIAL_VALUE);

  public next(context: ITopbarNavigation): void {
    this.context.next(context);
  }

  constructor() {}

  get isShown() {
    return this.context.getValue().show
  }

  hideTopBar() {
    this.next({
      show: false
    });
  }
}
