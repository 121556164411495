<ng-template #imageTemplate>
  <div *ngIf="section?.images?.length > 0 ">
    <div
      *ngFor="let wrapper of images; index as i;"
      [ngClass]="classForImageSize(wrapper.size)"
      [class.embeddedView]="embeddedView"
      [class.admin-mode]="itsAdminMode"
    >
      <app-section-image [image]="wrapper"></app-section-image>
      <div
        class="polaroid-container"
        *ngIf="editMode  || wrapper.description && wrapper.description.length > 0"
        [class.admin-mode]="itsAdminMode"
      >
        <ng-container *ngIf="editMode">
          <div class="polaroid-container__controls">
            <input
              value=""
              class="polaroid-container__input"
              placeholder="Titel"
              [value]="wrapper.description"
              [(ngModel)]="wrapper.description"
              (keyup)="titleChanged()"
            />
            <button
              (click)="updateImages(false)"
              class="polaroid-container__btn btn success"
              [class.disabled]="titleDidChange === false"
            >
              <i class="fa fa-save"></i>
            </button>
            <button
              class="polaroid-container__btn btn danger"
              (click)="dropImage(wrapper,false)"
            >
              <i class="fa fa-trash"></i>
            </button>
          </div>
          <div class="polaroid-container__controls">
            <label
              class="polaroid-container__control-radio"
              for="{{i}}_label_sm"
            >
              <input
                id="{{i}}_label_sm"
                type="radio"
                [(ngModel)]="wrapper.size"
                [value]="0"
                [checked]="wrapper.size == 1"
                (ngModelChange)="updateImages(false)"
              />
              SM
            </label>
            <label
              class="polaroid-container__control-radio"
              for="{{i}}_label_md"
            >
              <input
                id="{{i}}_label_md"
                type="radio"
                [(ngModel)]="wrapper.size"
                [value]="1"
                [checked]="wrapper.size == 2"
                (ngModelChange)="updateImages(false)"
              />
              MD
            </label>

            <label
              class="polaroid-container__control-radio"
              for="{{i}}_label_lg"
            >
              <input
                id="{{i}}_label_lg"
                type="radio"
                [(ngModel)]="wrapper.size"
                [value]="2"
                [checked]="wrapper.size == 3"
                (ngModelChange)="updateImages(false)"
              />
              LG
            </label>
          </div>
        </ng-container>
        <span
          *ngIf="!editMode && wrapper.description && wrapper.description.length > 0 "
          >{{wrapper.description}}</span
        >
      </div>
    </div>
  </div>
</ng-template>

<ng-template #mobileImageTemplate>
  <div *ngIf="section?.mobileImages?.length > 0 ">
    <div
      [ngClass]="classForImageSize(wrapper.size)"
      *ngFor="let wrapper of mobileImages"
      [class.admin-mode]="itsAdminMode"
    >
      <app-section-image [image]="wrapper"></app-section-image>
      <div
        class="polaroid-container"
        *ngIf="editMode  || wrapper.description && wrapper.description.length > 0"
      >
        <ng-container *ngIf="editMode">
          <button class="btn danger" (click)="dropImage(wrapper,true)">
            <i class="fa fa-trash"></i>
          </button>
          <input
            value=""
            class="mat-input-element"
            placeholder="Titel"
            [value]="wrapper.description"
            [(ngModel)]="wrapper.description"
            (keyup)="titleChanged()"
          />
          <button
            (click)="updateImages(true)"
            class="mat-button"
            *ngIf="titleDidChange"
          >
            <i class="fa fa-save"></i>
          </button>
          <br />
          <input
            type="radio"
            [(ngModel)]="wrapper.size"
            [value]="0"
            [checked]="wrapper.size == 1"
            (ngModelChange)="updateImages(true)"
          />
          SM
          <input
            type="radio"
            [(ngModel)]="wrapper.size"
            [value]="1"
            [checked]="wrapper.size == 2"
            (ngModelChange)="updateImages(true)"
          />
          MD
          <input
            type="radio"
            [(ngModel)]="wrapper.size"
            [value]="2"
            [checked]="wrapper.size == 3"
            (ngModelChange)="updateImages(true)"
          />
          LG
        </ng-container>
        <span
          *ngIf="!editMode && wrapper.description && wrapper.description.length > 0 "
          >{{wrapper.description}}</span
        >
      </div>
    </div>
  </div>
</ng-template>

<ng-template #videoButtonsTemplate>
  <ng-container *ngIf="section?.videos?.length > 0 ">
    <ng-container *ngFor="let video of section?.videos">
      <button
        class="button our-button btn accent show-video-button"
        (click)="toggleExpanded(video)"
      >
        <i class="fas fa-video"></i>
        {{ (expanded[video.public_id]) ? 'Video verstecken' : 'Video anzeigen'}}
      </button>
      <button
        *ngIf="editMode"
        class="btn danger button our-button delete-video-button"
        (click)="dropVideo(video)"
      >
        video löschen
      </button>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #videoTemplate>
  <ng-container *ngIf="section?.videos?.length > 0 ">
    <ng-container *ngFor="let video of section?.videos">
      <div [@showVideoAnimation] *ngIf="expanded[video.public_id]">
        <app-video-player [videoSource]="video"></app-video-player>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-container *ngIf="info.imagePosition == ImagePosition.aboveBox">
  <ng-container *ngIf="itsAdminMode || !showMobile">
    <ng-container *ngTemplateOutlet="imageTemplate"></ng-container>
  </ng-container>
  <ng-container *ngIf="itsAdminMode || showMobile">
    <ng-container *ngTemplateOutlet="mobileImageTemplate"></ng-container>
  </ng-container>
</ng-container>
<section
  class="clearfix container-fluid section-root"
  (click)="onClickEvaluation()"
  [ngClass]="customClasses"
  [class.section_hidden]="section.type === sectionTypes.AdminsOnly && (mediaCaptureService.isInProgress$| async)"
>
  <div
    class="row"
    [class.progressable]="progressState"
    [class.closed]="!showSection"
  >
    <div
      [class]="'col container-fluid bodybox section-toggle ' + boxClassString"
      [class.admin-mode]="itsAdminMode"
    >
      <h6 class="section-header" *ngIf="editMode">
        {{ (titlePrefix || "") + " " + (section?.title || "")}}
      </h6>
      <div class="toggle-section" *ngIf="info.expandable && !editMode">
        <button
          class="btn light section-btn section-btn__toggle"
          (click)="toggleShow()"
          [class.open]="showSection"
          [class.not-understood]="(isNotUnderstood | async)"
          [class.partly-understood]="(isPartlyUnderstood | async)"
          [class.fully-understood]="(isFullyUnderstood | async)"
          [innerHTML]="((showSection) ?  titlePrefix + title +' verstecken' : titlePrefix + title+' anzeigen') | renderMath"
        ></button>

        <app-bookmark class="section__bookmark" *ngIf="showSection"
                      [bookmarked]="!!(userdata?.bookmarkedAt)"
                      [loading]="isBookmarkUpdating"
                      (onBookmark)="bookmarkSection()"
                      (onUnBookmark)="unBookmarkSection()"
        ></app-bookmark>
      </div>

      <ng-container *ngIf="showSection || !info.expandable || editMode">
        <ng-container [ngSwitch]="section?.type">
          <div class="section-content__wrapper">
            <div class="section-content">
              <div
                *ngSwitchCase="sectionTypes.Tipps"
                [class]="info.classString"
              >
                <div class="row-eq-height">
                  <div class="iconbox col-xs">
                    <i class="fas fa-lightbulb"></i>
                  </div>
                  <h6
                    *ngIf="!info.expandable"
                    [innerHTML]="(titlePrefix + title | renderMath)"
                  ></h6>
                </div>
                <div class="content col-xs">
                  <span [innerHTML]="content"></span>
                </div>
                <ng-container
                  *ngIf="info.imagePosition == ImagePosition.insideBox"
                >
                  <ng-container *ngIf="itsAdminMode || !showMobile">
                    <ng-container
                      *ngTemplateOutlet="imageTemplate"
                    ></ng-container>
                  </ng-container>
                  <ng-container *ngIf="itsAdminMode || showMobile">
                    <ng-container
                      *ngTemplateOutlet="mobileImageTemplate"
                    ></ng-container>
                  </ng-container>
                  <div class="row col-md" *ngIf="section?.videos?.length > 0 ">
                    <ng-container
                      *ngTemplateOutlet="videoButtonsTemplate"
                    ></ng-container>
                  </div>
                  <br />
                  <div
                    class="row col-md video-wrapper"
                    *ngIf="section?.videos?.length > 0 && showSection && info.imagePosition == ImagePosition.insideBox"
                  >
                    <ng-container
                      *ngTemplateOutlet="videoTemplate"
                    ></ng-container>
                  </div>
                </ng-container>
              </div>
              <div
                *ngSwitchCase="sectionTypes.NerdKnowledge"
                [class]="info.classString"
              >
                <div class="row-eq-height">
                  <div class="iconbox col-xs">
                    <i class="fas fa-lightbulb"></i>
                  </div>
                  <h6
                    *ngIf="!info.expandable"
                    [innerHTML]="(titlePrefix + title | renderMath)"
                  ></h6>
                </div>
                <div class="content col-xs">
                  <span [innerHTML]="content"></span>
                </div>
                <ng-container
                  *ngIf="info.imagePosition == ImagePosition.insideBox"
                >
                  <ng-container *ngIf="itsAdminMode || !showMobile">
                    <ng-container
                      *ngTemplateOutlet="imageTemplate"
                    ></ng-container>
                  </ng-container>
                  <ng-container *ngIf="itsAdminMode || showMobile">
                    <ng-container
                      *ngTemplateOutlet="mobileImageTemplate"
                    ></ng-container>
                  </ng-container>
                  <div class="row col-md" *ngIf="section?.videos?.length > 0 ">
                    <ng-container
                      *ngTemplateOutlet="videoButtonsTemplate"
                    ></ng-container>
                  </div>
                  <br />
                  <div
                    class="row col-md video-wrapper"
                    *ngIf="section?.videos?.length > 0 && showSection && info.imagePosition == ImagePosition.insideBox"
                  >
                    <ng-container
                      *ngTemplateOutlet="videoTemplate"
                    ></ng-container>
                  </div>
                </ng-container>
              </div>
              <div *ngSwitchCase="sectionTypes.Tip" [class]="info.classString">
                <div class="row-eq-height">
                  <div class="iconbox col-xs">
                    <i class="fas fa-lightbulb"></i>
                  </div>
                  <h6
                    *ngIf="!info.expandable"
                    [innerHTML]="(titlePrefix + title | renderMath)"
                  ></h6>
                </div>
                <div class="content col-xs">
                  <span [innerHTML]="content"></span>
                </div>
                <ng-container
                  *ngIf="info.imagePosition == ImagePosition.insideBox"
                >
                  <ng-container *ngIf="itsAdminMode || !showMobile">
                    <ng-container
                      *ngTemplateOutlet="imageTemplate"
                    ></ng-container>
                  </ng-container>
                  <ng-container *ngIf="itsAdminMode || showMobile">
                    <ng-container
                      *ngTemplateOutlet="mobileImageTemplate"
                    ></ng-container>
                  </ng-container>
                  <div class="row col-md" *ngIf="section?.videos?.length > 0 ">
                    <ng-container
                      *ngTemplateOutlet="videoButtonsTemplate"
                    ></ng-container>
                  </div>
                  <br />
                  <div
                    class="row col-md video-wrapper"
                    *ngIf="section?.videos?.length > 0 && showSection && info.imagePosition == ImagePosition.insideBox"
                  >
                    <ng-container
                      *ngTemplateOutlet="videoTemplate"
                    ></ng-container>
                  </div>
                </ng-container>
              </div>
              <div *ngSwitchCase="'Rules'" [class]="info.classString">
                <div class="row-eq-height">
                  <div class="iconbox col-xs">
                    <img
                      crossorigin
                      height="87px"
                      width="100px"
                      src="./assets/images/exc-white.svg"
                      alt="!"
                    />
                  </div>
                  <h6
                    *ngIf="!info.expandable"
                    [innerHTML]="(titlePrefix +title |renderMath)"
                  ></h6>
                </div>
                <div class="content col-xs">
                  <span [innerHTML]="content"></span>
                </div>
                <ng-container
                  *ngIf="info.imagePosition == ImagePosition.insideBox"
                >
                  <br />
                  <ng-container *ngIf="itsAdminMode || !showMobile">
                    <ng-container
                      *ngTemplateOutlet="imageTemplate"
                    ></ng-container>
                  </ng-container>
                  <ng-container *ngIf="itsAdminMode || showMobile">
                    <ng-container
                      *ngTemplateOutlet="mobileImageTemplate"
                    ></ng-container>
                  </ng-container>
                  <div class="row col-md" *ngIf="section?.videos?.length > 0 ">
                    <ng-container
                      *ngTemplateOutlet="videoButtonsTemplate"
                    ></ng-container>
                  </div>
                  <div
                    class="col-md row flex-row video-wrapper"
                    *ngIf="section?.videos?.length > 0 && showSection && info.imagePosition == ImagePosition.insideBox"
                  >
                    <br />
                    <ng-container
                      *ngTemplateOutlet="videoTemplate"
                    ></ng-container>
                  </div>
                </ng-container>
              </div>
              <div *ngSwitchCase="'Rules2'" [class]="info.classString">
                <div class="row-eq-height">
                  <div class="content col-md">
                    <h6
                      *ngIf="!info.expandable"
                      [innerHTML]="(titlePrefix + title |renderMath)"
                    ></h6>
                    <span [innerHTML]="content"></span>
                  </div>
                </div>
              </div>
              <div *ngSwitchDefault [class]="info.classString">
                <h6
                  *ngIf="!(info.expandable) && title"
                  [innerHTML]="(titlePrefix + title |renderMath)"
                ></h6>
                <span [innerHTML]="content"></span>
                <ng-container
                  *ngIf="info.imagePosition == ImagePosition.insideBox"
                >
                  <div
                    class="flex-row"
                    *ngIf="itsAdminMode || !showMobile && section?.images?.length > 0 && showSection && info.imagePosition == ImagePosition.insideBox"
                  >
                    <ng-container
                      *ngTemplateOutlet="imageTemplate"
                    ></ng-container>
                  </div>
                  <div class="flex-row" *ngIf="itsAdminMode || showMobile">
                    <ng-container
                      *ngTemplateOutlet="mobileImageTemplate"
                    ></ng-container>
                  </div>
                  <div class="" *ngIf="section?.videos?.length > 0 ">
                    <ng-container
                      *ngTemplateOutlet="videoButtonsTemplate"
                    ></ng-container>
                  </div>
                  <div
                    class="flex-row video-wrapper"
                    *ngIf="section?.videos?.length > 0 && showSection && info.imagePosition == ImagePosition.insideBox"
                  >
                    <ng-container
                      *ngTemplateOutlet="videoTemplate"
                    ></ng-container>
                  </div>
                </ng-container>
              </div>
            </div>

            <div
              class="chapter-bottom section-bottom"
              *ngIf="progressState"
              [class.forbidden]="(loginService.isAnonymous | async)"
              [ngClass]="{'chapter-bottom__hidden': (mediaCaptureService.isInProgress$ | async)}"
            >
              <app-section-bottom-tabs>
                <app-section-bottom-tab *ngIf="isChatbotEnabled">
                  <ng-template #title>
                    <app-icon-sparkle
                      class="section-bottom__ai-icon"
                    ></app-icon-sparkle>
                    Ai fragen
                  </ng-template>
                  <ng-template #content>
                    <app-chatbot [section]="section"></app-chatbot>
                  </ng-template>
                </app-section-bottom-tab>

                <app-section-bottom-tab *ngIf="allowComments">
                  <ng-template #title>
                    <img src="./assets/studyprime/png/comments.png" />
                    Tutor/in fragen
                  </ng-template>
                  <ng-template #content>
                    <comments
                      [section]="section"
                      [chapterId]="chapterId"
                      [editMode]="isCommentsAdmin"
                      [requireVideoBeforeCommenting]="
                        (requireVideoBeforeCommenting$ | async) &&
                        !isCommentsAdmin && hasVideo && !(hadVideoExpanded$ | async)
                      "
                      *ngIf="allowComments"
                    ></comments>
                    <div class="divider"></div>
                  </ng-template>
                </app-section-bottom-tab>

                <app-section-bottom-tab
                  *ngIf="allowProgress"
                  desktopTitleWidth="auto"
                >
                  <ng-template #title>
                    <app-progress
                      [progressState]="featureFlaggedProgressState"
                      (fullyUnderstood)="fullyUnderstood.emit()"
                      (partlyUnderstood)="partlyUnderstood.emit()"
                      (notUnderstood)="notUnderstood.emit()"
                      [isUpdating]="isUpdatingProgress"
                      class="desktop-app-progress-controls"
                    >
                    </app-progress>
                  </ng-template>
                </app-section-bottom-tab>
              </app-section-bottom-tabs>

              <div class="forbidden-popup">
                <span
                  >Registriere dich bitte, um diese Funktion nutzen zu
                  können</span
                >
                <span>(weiterhin kostenlos)</span>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div
    class="row col-md"
    *ngIf="section?.videos?.length > 0 && showSection && info.imagePosition == ImagePosition.belowBox"
  >
    <ng-container *ngTemplateOutlet="videoButtonsTemplate"></ng-container>
  </div>
  <div
    class="row col-md video-wrapper"
    *ngIf="section?.videos?.length > 0 && showSection && info.imagePosition == ImagePosition.belowBox"
  >
    <ng-container *ngTemplateOutlet="videoTemplate"></ng-container>
  </div>

  <ng-container *ngIf="info.imagePosition == ImagePosition.belowBox">
    <ng-container *ngIf="itsAdminMode || !showMobile">
      <ng-container *ngTemplateOutlet="imageTemplate"></ng-container>
    </ng-container>
    <ng-container *ngIf="itsAdminMode || showMobile">
      <ng-container *ngTemplateOutlet="mobileImageTemplate"></ng-container>
    </ng-container>
  </ng-container>

  <app-disable-chatbot
    *ngIf="showChatbotDisabledBy"
    [chatbotDisabledBy]="section.chatbotDisabledBy"
    (chatbotDisabledByChanged)="setChatbotDisabled($event)"
  ></app-disable-chatbot>
  <app-chatbot-disabled-reason
    *ngIf="showChatbotDisabledReason"
    [chatbotDisabledBy]="section.chatbotDisabledBy"
    [dataQualityReason]="section.dataQualityReason"
  >
  </app-chatbot-disabled-reason>

  <app-custom-styles
    *ngIf="showCustomStyles"
    [section]="section"
    (onChange)="onCustomStylesChange.emit($event)"
  ></app-custom-styles>
</section>
