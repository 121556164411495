import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import BasicRxComponent from '@components/BasicRxComponent';
import { LoaderModule } from '@components/loader/loader.module';
import {
  MediaCaptureService,
  MediaType,
  RecordStatus,
  IUploadResult,
  UploadResult,
  isUploadError
} from '@services/media-capture/';
import { combineLatest, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-media-capture',
  standalone: true,
  imports: [CommonModule, LoaderModule],
  template: `
    <button
      title="record video"
      class="btn outline primary btn_iconed"
      [ngClass]="{
        disabled: (recordUpload$ | async),
      }"
      (click)="captureMedia(mediaTypes.VIDEO)"
      [ngSwitch]="videoRecordStatus$ | async"
    >
      <app-element-loader *ngSwitchCase="'upload'"></app-element-loader>
      <i *ngSwitchCase="'inprogress'" class="fa-solid fa-stop"></i>
      <i *ngSwitchDefault class="fa-solid fa-video"></i>
    </button>

    <button
      title="record audio"
      class="btn outline primary btn_iconed"
      [ngClass]="{ disabled: (recordUpload$ | async) }"
      (click)="captureMedia(mediaTypes.AUDIO)"
      [ngSwitch]="audioRecordStatus$ | async"
    >
      <app-element-loader *ngSwitchCase="'upload'"></app-element-loader>
      <i *ngSwitchCase="'inprogress'" class="fa-solid fa-stop"></i>
      <i *ngSwitchDefault class="fa-solid fa-microphone"></i>
    </button>
  `,
  styleUrl: './media-capture.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaCaptureComponent
  extends BasicRxComponent
  implements OnInit, OnDestroy
{
  @Output() recordSuccess = new EventEmitter<IUploadResult>();
  @Output() recordError = new EventEmitter<string>();
  @Output() recordStatusChange = new EventEmitter<RecordStatus>();
  isActive = false;
  mediaTypes = MediaType;

  scrollCoordinatesWhenStart = { y: 0, x: 0 };

  private floatingControl: HTMLElement;

  constructor(
    public mediaCaptureService: MediaCaptureService,
    private renderer: Renderer2,
    private router: Router
  ) {
    super();
  }

  public get videoRecordStatus$() {
    return this.getRecordStatus$(MediaType.VIDEO);
  }

  public get audioRecordStatus$() {
    return this.getRecordStatus$(MediaType.AUDIO);
  }

  public get recordUpload$() {
    return this.mediaCaptureService.recordStatus$.pipe(
      map((status: RecordStatus) => {
        return status === 'upload';
      })
    );
  }

  public async captureMedia(type: MediaType): Promise<void> {
    this.beforeStart();
    try {
      const result$ = await this.mediaCaptureService.startRecord(type);

      if (result$) {
        this.scrollCoordinatesWhenStart = {
          y: window.scrollY,
          x: window.scrollX
        };
        this.addFloatingControlToBody(type);
        const subcribtion = result$.subscribe((result: UploadResult) => {
          if (isUploadError(result)) {
            this.recordError.emit(result.message);
          } else {
            this.recordSuccess.emit(result);
          }
          this.cleanUp();
          subcribtion.unsubscribe();
        });
      }
    } catch (e) {
      console.error(e);
      this.cleanUp();
    }
  }

  ngOnInit() {
    this.registerMediaServiceListeners();
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: BeforeUnloadEvent): void {
    if (this.mediaCaptureService.isRecordingInProgress()) {
      $event.returnValue = true;
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private addFloatingControlToBody(type: MediaType): void {
    // Create the element
    this.floatingControl = this.renderer.createElement('button');
    this.floatingControl.classList.add(
      'media-capturere__btn',
      'btn',
      'outline',
      'danger',
      'btn_iconed',
      'floating'
    );
    this.floatingControl.title = 'Stop recording';
    const statusSubscription = this.getRecordStatus$(type).subscribe(
      (status) => {
        switch (status) {
          case 'inprogress':
            this.floatingControl.classList.add('animated');
            this.floatingControl.innerHTML = '<i class="fa-solid fa-stop"></i>';
            break;
          case 'upload':
            this.floatingControl.classList.remove('animated');
            this.floatingControl.innerHTML =
              '<i class="fa fa-spinner animated loading"></i>';
            this.floatingControl.classList.add('success');
            this.floatingControl.classList.remove('danger');
            break;
          case 'success':
            statusSubscription.unsubscribe();
            break;
          default:
            this.floatingControl.classList.remove('animated');
            this.floatingControl.innerHTML = '<i class="fa-solid fa-stop"></i>';
        }
      }
    );
    this.bag.add(statusSubscription);

    const clickHandler = () => {
      this.mediaCaptureService.stopRecord();
      this.floatingControl.removeEventListener('click', clickHandler);
    };
    this.floatingControl.innerHTML = '<i  class="fa-solid fa-stop"></i>';
    this.floatingControl.addEventListener('click', clickHandler);

    this.renderer.appendChild(document.body, this.floatingControl);
  }

  private removeFloatingControlFromBody(): void {
    if (this.floatingControl) {
      this.renderer.removeChild(document.body, this.floatingControl);
      const { x, y } = this.scrollCoordinatesWhenStart;
      window.scrollTo(x, y);
      this.floatingControl = null;
    }
  }

  private cleanUp() {
    // Wrapped in timeout to be sure all async events were handled
    setTimeout(() => {
      this.isActive === false;
      this.removeFloatingControlFromBody();
      this.bag.dispose();
    }, 0);
  }

  private beforeStart() {
    this.isActive = true;
    this.registerMediaServiceListeners();
  }

  private registerMediaServiceListeners() {
    this.subscribeToRouterChange();
    this.subscribeToRecordStatusChange();
    this.subscribeToError();
  }

  private subscribeToError() {
    this.bag.add(
      this.mediaCaptureService.error$.subscribe((e) => {
        if (this.isActive) {
          this.recordError.emit(e);
          this.cleanUp();
        }
      })
    );
  }

  private subscribeToRecordStatusChange() {
    this.bag.add(
      this.mediaCaptureService.recordStatus$.subscribe(
        (status: RecordStatus) => {
          this.recordStatusChange.emit(status);
        }
      )
    );
  }

  private subscribeToRouterChange() {
    // Listen to router events
    this.bag.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          if (this.mediaCaptureService.isRecordingInProgress()) {
            const confirmNavigation = window.confirm(
              'Recording is in progress. Do you really want to leave?'
            );
            if (!confirmNavigation) {
              this.router.navigateByUrl(this.router.url); // Stay on the current page
            }
          }
        }
      })
    );
  }

  private getRecordStatus$(mediaType: MediaType) {
    return combineLatest([
      this.mediaCaptureService.recordStatus$,
      of(this.mediaCaptureService.mediaCapturerType)
    ]).pipe(
      filter(() => {
        return this.isActive
      }),
      map(([status, type]) => {
        if (type === mediaType) {
          return status;
        }
        return null;
      })
    );
  }
}
