import { Injectable } from '@angular/core';
import { ILocalStorageService } from './types';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageServiceMock implements ILocalStorageService {
  private _store: Record<string, string> = {};

  constructor() {}
  public get store() {
    return this._store;
  }
  getItem(key: string): string | null {
    return key in this._store ? this._store[key] : null;
  }

  setItem(key: string, value: string): void | null {
    this._store[key] = `${value}`;
  }

  removeItem(key: string): void | null {
    delete this._store[key];
  }
  clear(): void {
    this._store = {};
  }
}
