import { Component, OnDestroy } from '@angular/core';
import { DisposeBag } from '@utils/DisposeBag';

@Component({
  template: ''
})
export default class BasicRxComponent implements OnDestroy {
  protected bag = new DisposeBag();

  ngOnDestroy(): void {
    this.bag.dispose();
  }
}
