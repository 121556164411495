/* eslint-disable @typescript-eslint/no-unused-vars */
import {IAuthProfile, IAuthService, ILoginState} from './types'
import { BehaviorSubject, Observable } from 'rxjs';
import { Preference } from '@models/preference';

export class LoginServiceMock implements IAuthService {
  profileInfo: BehaviorSubject<IAuthProfile | null> = new BehaviorSubject(null);
  isAdmin: Observable<boolean> = new Observable();
  currentIsAdmin = false;
  loginstate: ILoginState = {bookmarkCourse: '1'};
  isReady: BehaviorSubject<boolean>;
  isLoggedIn: BehaviorSubject<boolean>;
  isAnonymous: BehaviorSubject<boolean>;
  loggedInValue: boolean;
  idToken: string;
  hasPurchasedCourse(_courseId: string): boolean {
    return true
  }
  storePreference(_preference: Preference, _value: string): void {

  }
  storePreferenceForImmediateKey(_preference: string, _value: string): void {
    return undefined
  }
  retrievePreferenceByImmediateKey(_preference: string): string {
    return '123'
  }
  retrievePreference(_preference: Preference): string {
    return '123'
  }
  reLoginRestoringState() {
    return undefined
  }
  loginBookingCourse(_course: string, _register: boolean): void {
    return undefined
  }
  login(
    _nonce: string,
    _state: ILoginState,
    _register: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> | undefined {
    return Promise.resolve()
  }
  logout(_redirect: boolean): void {
    return undefined
  }
  getAnonymous(): Promise<void> {
    return Promise.resolve()
  }

  handleSessionChangeIfNecessary(): void {
  }
}
