import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import Player from '@vimeo/player';
import {
  IVideoPlayer,
  IVideoPlayerControllable,
  IVideoSource,
  PlayerStateChangeAction,
  PLAYER_STATE_TYPES
} from '../../types';
import { VideoPlayerComponent } from '../video-player/video-player.component';

@Component({
  selector: 'app-video-player-custom-controls',
  template: `
    <app-video-player
      #playerComponent
      [videoSource]="videoSource"
      [config]="playerConfig"
      (onPlayerStateChange)="onPlayerStateChange($event)"
    >
    </app-video-player>
    <button class="btn btn-play-pause" (click)="togglePlay()">
      <mat-icon class="icon" *ngIf="!isPlaying">play_circle_filled</mat-icon>
      <mat-icon class="icon" *ngIf="isPlaying">pause_circle_filled</mat-icon>
    </button>
    <button class="btn btn-fullscreen" (click)="toggleFullScreen()">
      <mat-icon class="icon">fullscreen</mat-icon>
    </button>
  `,
  styleUrls: ['./video-player-custom-controls.component.scss']
})
export class VideoPlayerCustomControlsComponent
  implements IVideoPlayer, IVideoPlayerControllable
{
  @Input() videoSource: IVideoSource | null = null;
  @ViewChild('playerComponent') private playerComponent: VideoPlayerComponent;

  @HostBinding('class.playing') get isPlayingClassName() {
    return this.isPlaying
  }

  private playerInstance: Player | null = null;

  public get isPlaying() {
    return this.playerComponent?.isPlaying
  }

  public get playerConfig() {
    return {
      controls: false
    };
  }

  public togglePlay() {

    if (this.isPlaying) {
      this.pause();
    } else {
      this.play();
    }
  }

  public async toggleFullScreen() {
    await this.playerInstance.requestFullscreen();
  }

  public play() {
    this.playerComponent.play();
  }

  public pause() {
    this.playerComponent.pause();
  }

  public stop() {
    this.playerComponent.stop();
  }

  public onPlayerStateChange({ type, payload }: PlayerStateChangeAction<Player | undefined>) {
    if(type === PLAYER_STATE_TYPES.INIT) {
      this.onPlayerInit(payload);
    }
  }

  private async toggleControls(isVisible) {
    const currentTime = await this.playerInstance.getCurrentTime();

    await this.playerInstance.loadVideo({
      ...this.videoSource,
      controls: isVisible
    });
    // BUG with multiple playing -> fullscreen
    await this.playerInstance.setCurrentTime(currentTime);
    await this.playerInstance.play();
  }

  private setInstance(player: Player) {
    this.playerInstance = player;
  }

  private onPlayerInit(player: Player) {
    this.setInstance(player);
    this.subscribeToInstanceEvents();
  }

  private subscribeToInstanceEvents() {
    this.playerInstance.on('fullscreenchange', ({ fullscreen }) => {
      this.toggleControls(fullscreen);
    });
  }
}
