<ng-container [ngTemplateOutlet]="currentTemplate"></ng-container>

<ng-template #templateVideoContent>
  <app-video-content [media]="media"></app-video-content>
</ng-template>

<ng-template #templateAudioContent>
  <div>
    <audio [src]="media.link" controls="true" type="audio/webm"></audio>
  </div>
</ng-template>
