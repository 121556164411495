import { Injectable } from '@angular/core';
import { LoginService } from '@core/auth';
import { HttpService } from '@core/http';
import {
  IIngestionPayload,
  INGESTION_EVENT_TYPES,
  INGESTION_PAYLOAD_TYPE,
  ProgressStateValue
} from '@models/ingestion';
import { createIngestionUrl } from '@utils/urlFactory';
import { Observable, of } from 'rxjs';

export interface IIngestionService {
  sendScrollEvent(resourceURI: string): Observable<unknown>;
  sendNavigateEvent(resourceURI: string): Observable<unknown>;
  sendProgressEvent(
    resourceURI: string,
    targetState: ProgressStateValue
  ): Observable<unknown>;
}

@Injectable({
  providedIn: 'root'
})
export class IngestionService implements IIngestionService {
  constructor(
    private client: HttpService<unknown>,
    private loginService: LoginService
  ) {}

  sendScrollEvent(resourceURI: string) {
    const clientId = this.loginService.profileInfo.getValue()?.sub;

    const payload = this.createPayload({
      type: INGESTION_PAYLOAD_TYPE.SCROLL,
      resourceURI,
      clientId,
      event_type: INGESTION_EVENT_TYPES.ACTIVITY
    });

    return this.sendEvent(payload);
  }

  sendNavigateEvent(resourceURI: string) {
    const clientId = this.loginService.profileInfo.getValue()?.sub;
    const payload = this.createPayload({
      type: INGESTION_PAYLOAD_TYPE.NAVIGATE,
      resourceURI,
      clientId,
      event_type: INGESTION_EVENT_TYPES.ACTIVITY
    });

    return this.sendEvent(payload);
  }

  sendProgressEvent(resourceURI: string, targetState: ProgressStateValue) {
    const clientId = this.loginService.profileInfo.getValue()?.sub;
    const payload = this.createPayload({
      type: INGESTION_PAYLOAD_TYPE.NAVIGATE,
      resourceURI,
      clientId,
      targetState,
      event_type: INGESTION_EVENT_TYPES.PROGRESS
    });

    return this.sendEvent(payload);
  }

  private sendEvent(event: IIngestionPayload): Observable<unknown> {
    const url = createIngestionUrl('event', 'activity')
    if(url) {
      return this.client.post(
        url,
        event,
        {
          headers: {
            'Access-Control-Allow-Origin': createIngestionUrl()
          }
        }
      );
    }
    return of(null);
  }

  private createPayload({
    type,
    resourceURI,
    event_type,
    targetState,
    clientId
  }: {
    type: INGESTION_PAYLOAD_TYPE;
    resourceURI: string;
    event_type: INGESTION_EVENT_TYPES;
    targetState?: ProgressStateValue;
    clientId: string;
  }): IIngestionPayload {
    return {
      scope: {
        resourceURI
      },
      targetState,
      event_type,
      type,
      clientId
    };
  }
}
