<div class="custom-styles-component">
  <div class="custom-styles-component__title"
       [matTooltip]="'Auf diesen Abschnitt lassen sich Stylings anwenden um die Darstellung für Nutzer anzupassen'">
    Styling:
  </div>
  <div *ngFor="let style of selectedStyles" class="custom-styles-component__style"
       [matTooltip]="getTooltip(style)" (click)="removeStyle(style)">
    <div class="custom-styles-component__style_name">{{getName(style)}}</div>
    <div class="custom-styles-component__style_remove" tabindex="0" (keydown.enter)="removeStyle(style)"
    >
      <i class="fas fa-xmark"></i>
    </div>
  </div>
  <div class="custom-styles-component__input" *ngIf="unselectedStyles.length">
    <mat-select (valueChange)="addStyle($event)">
      <mat-option *ngFor="let style of unselectedStyles" [value]="style.value">
        {{getName(style)}}
      </mat-option>
    </mat-select>
  </div>
</div>
