/* eslint-disable @typescript-eslint/no-explicit-any */
import { BehaviorSubject } from 'rxjs';
import { IRouterService } from './router.service';

export class RouterServiceMock implements IRouterService {
  queryParams = new BehaviorSubject({});
  currentRoute = '/';

  updateQueryParams(params: Record<string, any>) {
    this.queryParams.next(params);
  }

  init() {}
}
