import { BehaviorSubject } from 'rxjs';

export interface AiMessage {
  text: BehaviorSubject<string>;
  ai: boolean;
  isLoading?: BehaviorSubject<boolean>;
  error?: boolean;
}

export enum ChatTarget {
  Course = 'course',
  Chapter = 'chapter',
  Section = 'section',
}

export interface ChatRequest {
  question: string;
  targetType: ChatTarget,
  targetId: string
}

export type Feedback = 'positive' | 'negative';

export interface ChatSystemMessage {
  sessionId?: string;
  limitReached?: boolean;
}
