<button
  class="button our-button primary show-video-button"
  (click)="toggleVideoPlayer()"
>
  <app-element-loader *ngIf="isLoading| async"></app-element-loader>
  <ng-container *ngIf="(isLoading| async) === false">
    {{ (isVideoExpand | async) ? 'Video verstecken' : 'Video anzeigen'}}
  </ng-container>
</button>
<div class="video-player-wrapper" *ngIf="isVideoExpand | async">
  <div *ngIf="(isLoading | async) === true">LOADING</div>
  <ng-container
    *ngIf="(isLoading | async) === false"
    [ngSwitch]="mediaFileData?.status"
  >
    <ng-container *ngSwitchCase="'available'">
      <app-video-player
        class="comment-text__video-player"
        [videoSource]="{url: mediaFileData.link}"
      ></app-video-player>
    </ng-container>
    <ng-container *ngSwitchCase="'transcode_starting'">
      <ng-container [ngTemplateOutlet]="notReadyTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'transcode'">
      <ng-container [ngTemplateOutlet]="notReadyTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'transcoding'">
      <ng-container [ngTemplateOutlet]="notReadyTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <p class="video-content__alert alert alert-danger">
        Es tut uns leid, das Video konnte nicht erfolgreich hochgeladen werden.
        Bitte kontaktieren Sie uns.
      </p>
    </ng-container>
  </ng-container>
</div>

<ng-template #notReadyTemplate>
  <p class="video-content__alert alert alert-success">
    Das Video wird verarbeitet und steht in wenigen Minuten zur Verfügung.
  </p></ng-template
>
