import { HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';

export enum ERROR_REASON {
  TOKENREFRESH = 'tokenrefresh',
  UNAUTHORISED = 'Unauthorized',
  MISSING_UNIVERSITY = 'missing main university',
  VERSIONS_MISMATCH = 'versions_missmatch',
  RETRY = 'retry'
}

export enum ERROR_STATUSES {
  NOT_FOUND = 404,
  FORBIDDEN = 403,
  NOT_AUTHORISED = 401,
  ENROLMENT_NOT_FOUND = 402,
  BAD_REQUEST = 400,
  SERVER_ERROR = 500,
  BAD_GATEWAY = 502
}

export type RequestOption<T = object> = {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  context?: HttpContext;
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]:
          | string
          | number
          | boolean
          | ReadonlyArray<string | number | boolean>;
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: T;
};

export const getReason = ({
  error
}: {
  error: string | { reason?: string; error?: string };
}): ERROR_REASON | string | undefined => {
  if (!error) {
    return;
  }

  if (typeof error === 'string') {
    return error;
  }

  if (error.reason) {
    return error.reason;
  }

  if (error.error) {
    return error.error;
  }
};

export interface StreamListener {
  started?: (response: Response) => void;
  next?: (line: string, response: Response) => void;
  error?: (error: Error, response: Response) => void | Promise<void>;
  done?: (lines: string[], response: Response) => void;
}
