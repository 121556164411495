import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IDialog } from './modal.types';
import { MODAL_IDS } from './modals.constants';

type Keys = keyof typeof MODAL_IDS;
export type MODAL_ID = (typeof MODAL_IDS)[Keys];
@Injectable({
  providedIn: null
})
export class ModalsService {
  public readonly MODAL_IDS = MODAL_IDS;
  private modals: Map<string, IDialog> = new Map();
  // Only use global modal if you ensure yourself that the modal, as well as it's parent is always present
  private globalModals: Map<MODAL_ID, IDialog> = new Map();

  private _onCloseCallbacks = new Map<string, () => void>();
  public onClose$: Subject<string> = new Subject();

  constructor(private router: Router) {
    this.subscribeToNavigationEnd();
  }

  remove(id: MODAL_ID): void {
    // remove modal from array of active modals
    this.modals.get(id)?.remove();
    this.modals.delete(id);
  }

  open(modal: IDialog, onCloseCallback?: () => void): void {
    const id = modal.id;
    this.remove(id);
    modal.open();
    this.modals.set(id, modal);
    this.unhideRootContainer(modal.rootElement);
    this._onCloseCallbacks.set(id, onCloseCallback);
  }

  registerGlobalModal(modal: IDialog) {
    if (!modal) {
      return;
    }
    // modals that are registered through this can be opened via id
    this.unregisterGlobalModal(modal.id);
    this.globalModals.set(modal.id, modal);
  }

  unregisterGlobalModal(id: MODAL_ID) {
    if (this.globalModals.has(id)) {
      this.globalModals.get(id).remove();
      this.globalModals.delete(id);
    }
  }

  openGlobalModal(id: MODAL_ID, onCloseCallback?: () => void) {
    // Note that a modal opened this way will soft-lock the app if it's parent component no longer exists. e.g. #1363
    // Therefore, only open global modals if you know with certainty, that the parent which created it still exists
    this.globalModals.get(id).open();
    this.unhideRootContainer(this.globalModals.get(id).rootElement);
    this._onCloseCallbacks.set(id, onCloseCallback);
  }

  close(modal: IDialog): void {
    const id = modal?.id;
    const targetModal = this.modals.get(id) || this.globalModals.get(id);
    // close modal specified by id
    if (!targetModal) {
      return;
    }
    const onClose = this._onCloseCallbacks.get(id);
    this.hideRootContainer(targetModal.rootElement);
    if (onClose) {
      onClose();
    }
    this.onClose$.next(id);
    this._onCloseCallbacks.delete(id);
  }

  public isModalAlreadyExists(modalId) {
    return this.modals.has(modalId);
  }

  private closeAll() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [_id, modal] of this.modals) {
      modal.close();
    }
  }

  private subscribeToNavigationEnd() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.closeAll();
      });
  }

  private unhideRootContainer(container: HTMLDivElement) {
    container.parentElement.classList.add('visible');
  }

  private hideRootContainer(container: HTMLDivElement) {
    container.parentElement.classList.remove('visible');
  }
}
