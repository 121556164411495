import { Injectable } from '@angular/core';
import Player from '@vimeo/player';

@Injectable({
  providedIn: 'root'
})
export class VideoPlayerService {
  private playerInstances: Map<string, Player> = new Map();
  private activePlayerId: string;
  constructor() {}

  public addInstance(id: string, player: Player): void {
    this.playerInstances.set(id, player);
  }

  public removeInstance(id: string) {
    const player = this.playerInstances.get(id);
    this.playerInstances.delete(id);
    player.destroy();
  }

  public async onPlayHandler(currentPlayerId: string): Promise<void> {
    if (this.activePlayerId && this.activePlayerId !== currentPlayerId ) {
      const player = this.playerInstances.get(this.activePlayerId)
      if (player) {
        await player.unload();
      }
    }
    this.activePlayerId = currentPlayerId;
  }
}
